exports.components = {
  "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-ztbp-6-fpyozjqpdbsaackeabgrm-node-modules-vp-digital-gatsby-plugin-index-src-index-index-tsx": () => import("./../../../node_modules/.pnpm/@vp+digital-gatsby-plugin-index@0.16.1_ztbp6fpyozjqpdbsaackeabgrm/node_modules/@vp/digital-gatsby-plugin-index/src/index/Index.tsx" /* webpackChunkName: "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-ztbp-6-fpyozjqpdbsaackeabgrm-node-modules-vp-digital-gatsby-plugin-index-src-index-index-tsx" */),
  "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-ztbp-6-fpyozjqpdbsaackeabgrm-node-modules-vp-digital-gatsby-plugin-index-src-page-index-page-index-tsx": () => import("./../../../node_modules/.pnpm/@vp+digital-gatsby-plugin-index@0.16.1_ztbp6fpyozjqpdbsaackeabgrm/node_modules/@vp/digital-gatsby-plugin-index/src/page-index/PageIndex.tsx" /* webpackChunkName: "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-ztbp-6-fpyozjqpdbsaackeabgrm-node-modules-vp-digital-gatsby-plugin-index-src-page-index-page-index-tsx" */),
  "component---src-components-pages-builder-index-tsx": () => import("./../../../src/components/pages/builder/index.tsx" /* webpackChunkName: "component---src-components-pages-builder-index-tsx" */),
  "component---src-components-pages-product-page-index-tsx": () => import("./../../../src/components/pages/productPage/index.tsx" /* webpackChunkName: "component---src-components-pages-product-page-index-tsx" */),
  "component---src-components-pages-save-index-tsx": () => import("./../../../src/components/pages/save/index.tsx" /* webpackChunkName: "component---src-components-pages-save-index-tsx" */),
  "component---src-components-pages-wrap-up-index-tsx": () => import("./../../../src/components/pages/wrapUp/index.tsx" /* webpackChunkName: "component---src-components-pages-wrap-up-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

